import React, { useContext, ReactElement, FC } from 'react'	
import { Navigate } from 'react-router-dom'	
import { AuthContext } from 'context/AuthContext/AuthContext'	

const PrivateRoutes: FC<{ children: ReactElement }> = (props) => {	
    const { token } = useContext(AuthContext)	

    return token ? props.children : <Navigate replace to="/error" />	
}	

export default PrivateRoutes
