import { models, Report, IReportEmbedConfiguration } from 'powerbi-client'
import { InitialReportConfig, SyncSlicersParams } from './report.d'

export const getInitialReportConfig = ({
    accessToken,
    embedUrl,
    pageName,
    filters,
}: InitialReportConfig): IReportEmbedConfiguration => {
    return {
        type: 'report',
        embedUrl,
        accessToken,
        tokenType: models.TokenType.Embed,
        pageName,
        filters,
        settings: {
            layoutType: models.LayoutType.Custom,
            customLayout: {
                displayOption: models.DisplayOption.FitToWidth,
            },
            bars: {
                actionBar: {
                    visible: true,
                },
            },
            navContentPaneEnabled: false,
            filterPaneEnabled: false,
            panes: {
                bookmarks: {
                    visible: false,
                },
                fields: {
                    expanded: true,
                },
                filters: {
                    expanded: false,
                    visible: false,
                },
                pageNavigation: {
                    visible: false,
                },
                selection: {
                    visible: true,
                },
                syncSlicers: {
                    visible: true,
                },
                visualizations: {
                    expanded: true,
                },
            },
        },
    }
}

export const fetchSlicersAndStates = async (report: Report) => {
    const activePage = await report?.getActivePage()
    const visuals = await activePage?.getVisuals()
    const slicers = visuals?.filter((visual) => visual.type === 'slicer')
    const slicerStatePromises = slicers?.map(async (slicer) => await slicer.getSlicerState())
    const slicerStates = await Promise.all(slicerStatePromises || [])
    return { slicers, slicerStates }
}

// Finds the slicer state that matches the slicer target and set its state
export const syncSlicers = async ({ targetedSlicers, targetedSlicerStates, sourcedSlicerStates }: SyncSlicersParams) => {
    targetedSlicers?.forEach((slicer, index) => {
        const state = sourcedSlicerStates?.find((sourceState) => {
            const sourcedSlicerTarget = sourceState.targets?.[0] as models.IColumnTarget
            const targetedSlicerTarget = targetedSlicerStates?.[index].targets?.[0] as models.IColumnTarget
            return (
                sourcedSlicerTarget?.table === targetedSlicerTarget?.table &&
                sourcedSlicerTarget?.column === targetedSlicerTarget?.column
            )
        })
        if (state) {
            slicer.setSlicerState(state)
        }
    })
}
