import React, { FC } from 'react'
import { Routes, Route } from 'react-router-dom'
import Dashboard from 'pages/Dashboard'
import ErrorPage from 'pages/ErrorPage'
import PrivateRoutes from './PrivateRoutes'

const AppRoutes: FC = () => {
    return (
        <Routes>
            <Route
                path={'/'}
                element={
                    <PrivateRoutes>
                        <Dashboard />
                    </PrivateRoutes>
                }
            />
            <Route path={'/error'} element={<ErrorPage />} />
            <Route path="*" element={<div>WRONG DIRECTION</div>} />
        </Routes>
    )
}

export default AppRoutes
