import styled from '@emotion/styled'
import { Button as MuiButton, CircularProgress, IconButton as MuiIconButton } from '@mui/material'

export const ExportButton = styled(MuiButton)`
    text-transform: none;
    margin: auto 15px auto auto;
    width: 150px;
    height: 30px;
`

export const Button = styled(MuiButton)`
    text-transform: none;
`

export const LoadingIcon = styled(CircularProgress)`
    margin-left: 0.5em;
`

export const PopoverContent = styled.div`
    padding: 20px;
    & .MuiLinearProgress-root {
        background-color: #d8d8d8;
    }
`

export const PopoverHeader = styled.div`
    display: flex;
    justify-content: space-between;
`

export const IconButton = styled(MuiIconButton)`
    padding: 0;
`

export const PercentParagraph = styled.div`
    font-size: smaller;
    color: #888;
    margin: 10px 0;
`

export const DownloadMessage = styled.article`
    margin-top: 20px;
`

export const DisclaimerMessage = styled.article`
    color: #888;
    font-size: smaller;
    margin-top: 20px;
`
