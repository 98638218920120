import { PDFSlicers, ExportStatus } from 'types/pdfExport'

export const initialExportStatus: ExportStatus = {
    completed: false,
    percent: 0,
}

export const slicersQueryParams: PDFSlicers = {
    currencyFilter: 'currency_filter',
    timePeriodFilter: 'time_period_filter',
    startDateFilter: 'start_date_filter',
    endDateFilter: 'end_date_filter',
    subGroupFilter: 'subgroup_filter',
}
