import styled from '@emotion/styled'
import { SnackbarProvider } from 'notistack'

export const NotificationProvider = styled(SnackbarProvider)`
    &.SnackbarItem-variantSuccess {
        background-color: #f2f9f1;
        border: 1px solid #00854066;
        border-left: 3px solid #00853f;
        color: #1a1a1a;
        & .MuiSvgIcon-root {
            color: #008540;
        }
    }

    &.SnackbarItem-variantError {
        background-color: #fcf2f2;
        border: 1px solid #c6000066;
        border-left: 3px solid #c60000;
        color: #1a1a1a;
        & .MuiSvgIcon-root {
            color: #c60000;
        }
    }

    &.SnackbarItem-variantInfo {
        background-color: #f4f9fb;
        border: 1px solid #1a7ead66;
        border-left: 3px solid #1a7ead;
        color: #1a1a1a;
        & .MuiSvgIcon-root {
            color: #1a7ead;
        }
    }

    &.SnackbarItem-variantWarning {
        background-color: #fffbf4;
        border: 1px solid #f7a82766;
        border-left: 3px solid #f7a827;
        color: #1a1a1a;
        & .MuiSvgIcon-root {
            color: #f7a827;
        }
    }
`
