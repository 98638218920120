import styled from '@emotion/styled'
import { IconButton } from '@mui/material'
import { ReactComponent as CxtFilterIcon } from 'assets/icons/filter.svg'

export const SubHeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 16vw;
    height: 1.5em;
    padding: 0 0.5em;
    font-size: 1.2vw;
`

export const AdvancedFilterBtn = styled(IconButton)`
    padding: 4px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    &:hover {
        border-color: #797979;
    }
`

export const FilterIcon = styled(CxtFilterIcon)`
    height: 1vw;
`

export const PopoverContainer = styled.div`
    min-width: 90px;
    width: 19vw;
`

export const PopoverHeader = styled.div`
    padding: 15px 0 0 20px;
    font-size: 1.2vw;
    font-weight: bold;
`

export const IframeWrapper = styled('div', { shouldForwardProp: (prop) => prop !== 'slicersCount' })<{
    slicersCount: number
}>`
    height: ${({ slicersCount }) => ((slicersCount || 5) * screen.width) / 20}px;
`
