import React, { FC, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
// Components & Helpers
import LoadingSpinner from 'components/common/LoadingSpinner'
import { AuthContext, initialAuthContextValue } from './AuthContext'
import { APIHelper } from 'helpers/APIHelper'
// Types
import { AxiosResponse } from 'axios'
import { ReportConfig } from 'types/report'
import { Props } from './AuthContext.d'

export const AuthProvider: FC<Props> = ({ children }) => {
    const apiHelper = APIHelper.getInstance()
    const navigate = useNavigate()
    const timerRef = useRef<NodeJS.Timer>()
    const [reportConfig, setReportConfig] = useState<ReportConfig>(initialAuthContextValue)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        requestToken()
    }, [])

    useEffect(() => {
        if (reportConfig.token) {
            const timeLeft = reportConfig.expiration - Date.now()
            timerRef.current = setTimeout(requestNewReportToken, timeLeft)

            return () => {
                clearTimeout(timerRef.current)
            }
        }
    }, [reportConfig.token])

    const requestToken = () => {
        const headers = apiHelper.getHeaders()
        apiHelper
            .get('/report', headers)
            .then((response) => {
                const report: ReportConfig = response.data
                setReportConfig({
                    token: report.token,
                    embedURL: report.embedURL,
                    tabs: report.tabs,
                    expiration: new Date(report.expiration).valueOf(),
                    sessionToken: report.sessionToken,
                    menu: report.menu,
                })
            })
            .catch((err) => {
                setReportConfig((prevConfig) => ({ ...prevConfig, error: err?.response?.data?.error }))
                checkAndHandleInvalidToken(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }
    
    const requestNewReportToken = () => {
        console.log('PBI: Requesting a new report token')
        apiHelper
            .get(`/token?session_token=${reportConfig.sessionToken}`)
            .then((response: AxiosResponse<ReportConfig>) => {
                console.log('PBI: New report token received')
                const { expiration, token } = response.data
                setReportConfig({
                    ...reportConfig,
                    expiration: new Date(expiration).valueOf(),
                    token,
                })
            })
            .catch((err) => {
                console.log('PBI: Failed to get a new report token', err)
                setReportConfig((prevConfig) => ({ ...prevConfig, error: err?.response?.data?.error }))
                checkAndHandleInvalidToken(err)
            })
    }

    const checkAndHandleInvalidToken = (error: Record<string, any>) => {
        // Stop requesting a new token if the session token is invalid
        if (error?.response?.data?.error?.code === 10103) {
            clearTimeout(timerRef.current)
            navigate('/error')
        }
    }

    return (
        <AuthContext.Provider value={reportConfig}>
            {loading ? <LoadingSpinner /> : children}
        </AuthContext.Provider>
    )
}
