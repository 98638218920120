import React, { FC, useContext, useEffect, useState } from 'react'
import { Badge, Popover } from '@mui/material'
// PowerBI
import 'powerbi-report-authoring'
import { PowerBIEmbed } from 'powerbi-client-react'
import { IReportEmbedConfiguration, Report } from 'powerbi-client'
// Contexts
import { AuthContext } from 'context/AuthContext'
// Helpers
import { getInitialReportConfig, fetchSlicersAndStates, syncSlicers } from 'helpers/report'
// Types
import { AdditionalFiltersProps } from './AdditionalFilters.d'
// Styles
import * as Styled from './AdditionalFilters.styled'

const AdditionalFilters: FC<AdditionalFiltersProps> = ({ reportSlicerStates, updateDashboardSlicers }) => {
    let slicersUpdated = false
    const { embedURL: embedUrl, token: accessToken, menu } = useContext(AuthContext)
    const initialReportConfig = getInitialReportConfig({ embedUrl, accessToken, pageName: menu?.pageName })
    const [reportConfig, setReportConfig] = useState<IReportEmbedConfiguration>(initialReportConfig)
    const [report, setReport] = useState<Report>()
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const [appliedFiltersCount, setAppliedFiltersCount] = useState<number>(0)
    const [additionalSlicersCount, setAdditionalSlicersCount] = useState<number>(0)

    useEffect(() => {
        report?.on('rendered', updateAdditionalSlicerStates)
    }, [report])

    useEffect(() => {
        report?.setAccessToken(accessToken).then(() => report.refresh())
        setReportConfig((prevConfig) => ({ ...prevConfig, accessToken }))
    }, [accessToken])

    const updateAdditionalSlicerStates = async () => {
        if (report && !slicersUpdated) {
            slicersUpdated = true
            const { slicers, slicerStates } = await fetchSlicersAndStates(report)
            setAdditionalSlicersCount(slicers.length)
            await syncSlicers({
                targetedSlicers: slicers,
                targetedSlicerStates: slicerStates,
                sourcedSlicerStates: reportSlicerStates,
            })
        }
    }

    const onOpenFiltersPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const onCloseFiltersPopover = async () => {
        // Close the popover and clean up
        setAnchorEl(null)
        slicersUpdated = false
        // Update Dashboard Slicers
        const { slicerStates } = await fetchSlicersAndStates(report!)
        updateDashboardSlicers(slicerStates)
        // Update Additional Filters badge counter
        const filtersCount = slicerStates.filter((state) => state.filters.length).length
        setAppliedFiltersCount(filtersCount)
    }

    const eventHandlersMap = new Map([
        [
            'loaded',
            () => {
                console.log('PBI: Additional Filters iframe has been loaded')
            },
        ],
        [
            'rendered',
            () => {
                console.log('PBI: Additional Filters iframe has been rendered')
            },
        ],
    ])

    return (
        <>
            <Styled.SubHeaderWrapper>
                <b>Filters</b>
                {menu && (
                    <Styled.AdvancedFilterBtn
                        id="pbi-additional-filters-btn"
                        title="Additional Filters"
                        onClick={onOpenFiltersPopover}
                    >
                        <Badge color="primary" badgeContent={appliedFiltersCount}>
                            <Styled.FilterIcon />
                        </Badge>
                    </Styled.AdvancedFilterBtn>
                )}
            </Styled.SubHeaderWrapper>

            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={onCloseFiltersPopover}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
            >
                <Styled.PopoverContainer>
                    <Styled.PopoverHeader>Additional Filters</Styled.PopoverHeader>
                    <Styled.IframeWrapper slicersCount={additionalSlicersCount}>
                        <PowerBIEmbed
                            embedConfig={reportConfig}
                            eventHandlers={eventHandlersMap}
                            cssClassName={'powerbi-iframe-embed'}
                            getEmbeddedComponent={(embeddedComponent) => {
                                setReport(embeddedComponent as Report)
                            }}
                        />
                    </Styled.IframeWrapper>
                </Styled.PopoverContainer>
            </Popover>
        </>
    )
}

export default AdditionalFilters
