import styled from '@emotion/styled'
import { Tab as MUITab } from '@mui/material'

export const NavigationHeader = styled.div`
    height: 55px;
    left: 0;
    top: 0;
`

export const Tab = styled(MUITab)`
    text-transform: none;
`
